import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Привет всем, я <span className="purple"> Павел </span>
            из <span className="purple"> России Москвы. </span>
            <br />
            В настоящее время я безработный, ищу работу или опыт.
            <br />
            Я прошел курсы скиллбокса (SkillBox).
            <br />
            <br />
            Помимо кодирования, я люблю заниматься и другими видами деятельности!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Программировать
            </li>
            <li className="about-activity">
              <ImPointRight /> Плавать
            </li>
            <li className="about-activity">
              <ImPointRight /> Путешествовать
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Стремитесь создавать вещи, которые имеют значение!"{" "}
          </p>
          <footer className="blockquote-footer">Павел</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
