import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import {FaLinkedinIn, FaTelegram} from "react-icons/fa";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
          <h3>Разработано Павлом П.</h3>
        </Col>
        <Col md="4" className="footer-copywright">
          <h3>Copyright © {year} cpl3Dev</h3>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://github.com/RyzenDeveloper"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillGithub />
              </a>
            </li>
            {/*<li className="social-icons">*/}
            {/*  <a*/}
            {/*    href="https://twitter.com/Soumyajit4419"*/}
            {/*    style={{ color: "white" }}*/}
            {/*    target="_blank" */}
            {/*    rel="noopener noreferrer"*/}
            {/*  >*/}
            {/*    <AiOutlineTwitter />*/}
            {/*  </a>*/}
            {/*</li>*/}
            <li className="social-icons">
              <a
                href="https://t.me/m/VUVO7IfvNGEy"
                style={{ color: "lightblue" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <FaTelegram />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.instagram.com/doseof_pavel"
                style={{ color: "pink" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillInstagram />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
