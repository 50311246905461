import React, {useRef, useState} from "react"; // Import useState
import {Container, Row, Col} from "react-bootstrap";
import myImg from "../../Assets/avatar_photo.svg";
import Tilt from "react-parallax-tilt";
import emailjs from "@emailjs/browser";
import "../../style.css";
// import {
//     AiFillGithub,
//     AiOutlineTwitter,
//     AiFillInstagram,
// } from "react-icons/ai";
// import { FaLinkedinIn } from "react-icons/fa";

export const ContactUs = () => {
    const form = useRef();
    const [formData, setFormData] = useState({
        user_name: "",
        user_email: "",
        message: ""
    });
    const [notification, setNotification] = useState({ show: false, message: "", type: "" });
    const [isLoading, setIsLoading] = useState(false);  // Состояние загрузки

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const validateForm = () => {
        let errors = [];
        if (!formData.user_name.trim() || !formData.user_email.trim() || !formData.message.trim()) {
            if (!formData.user_name.trim() && !formData.user_email.trim() && !formData.message.trim()) {
                errors.push("Форма не заполнена.");
            } else {
                errors.push("Одно или несколько полей не заполнены.");
            }
        }
        if (!formData.user_email.includes('@')) {
            errors.push("Email должен содержать '@'.");
        }
        return errors;
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const errors = validateForm();

        if (errors.length === 0) {
            setIsLoading(true);  // Устанавливаем состояние загрузки в true
            emailjs.sendForm('service_pfj5h49', 'template_9ojjlk3', form.current, 'DaEvnKrWyH8INiF0n')
                .then(response => {
                    console.log('УСПЕХ!', response.status, response.text);
                    setFormData({ user_name: "", user_email: "", message: "" });
                    showNotification("Сообщение успешно отправлено!", "success");
                    setIsLoading(false);  // Устанавливаем состояние загрузки в false
                }, err => {
                    console.error('ОШИБКА...', err);
                    showNotification("Не удалось отправить сообщение, попробуйте позже.", "error");
                    setIsLoading(false);  // Устанавливаем состояние загрузки в false
                });
        } else {
            showNotification(errors[0], "error");
        }
    };

    const showNotification = (message, type) => {
        setNotification({ show: true, message, type });
        setTimeout(() => setNotification({ show: false, message: "", type: "" }), 3000);
    };

    return (
        <form ref={form} onSubmit={sendEmail} className="form">
            <label className="form__label">Имя</label>
            <input
                type="text"
                name="user_name"
                value={formData.user_name}
                onChange={handleInputChange}
                className="form__input form__input--text"
            />

            <label className="form__label">Email</label>
            <input
                type="email"
                name="user_email"
                value={formData.user_email}
                onChange={handleInputChange}
                className="form__input form__input--email"
            />

            <label className="form__label">Сообщение</label>
            <textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                className="form__textarea"
            />

            <input type="submit" value="Отправить" className="form__submit" disabled={isLoading} />

            {isLoading && <div className="form__loading">Отправка...</div>}
            {notification.show && (
                <div className={`form__notification form__notification--${notification.type}`}>
                    {notification.message}
                </div>
            )}
        </form>
    );
};

function Home2() {
    return (
        <Container fluid className="home-about-section" id="about">
            <Container>
                <Row>
                    <Col md={8} className="home-about-description">
                        <h1 style={{fontSize: "2.6em"}}>
                            ПОЗВОЛЬТЕ МНE <span className="purple"> ПРЕДСТАВИТЬ </span> СЕБЯ
                        </h1>
                        <p className="home-about-body">
                            Привет! Я фронтенд-разработчик с глубокими знаниями в <b className="purple"> HTML, CSS,
                            JavaScript и
                            TypeScript. </b>
                            Я увлекаюсь созданием удобных и функциональных пользовательских интерфейсов, которые не
                            только выглядят
                            великолепно,
                            но и обеспечивают исключительное взаимодействие пользователя с продуктом.
                            <br/>
                            <br/>Мой профессиональный путь в программировании начался с классической веб-разработки,
                            и с тех пор я постоянно расширяю свои знания, включая последние тренды и технологии.
                            Особенно мне интересно использование современных фреймворков и библиотек,таких как<i> <b
                            className="purple"> React
                            и Vue.js </b> </i>,
                            для создания масштабируемых и оптимизированных приложений.
                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*Моя сфера интересов - строительство новых &nbsp;*/}
                            {/*<i>*/}
                            {/*  <b className="purple">Веб-технологии и продукты </b> и*/}
                            {/*  также в областях, связанных с{" "}*/}
                            {/*  <b className="purple">*/}
                            {/*    Блокчейн.*/}
                            {/*  </b>*/}
                            {/*</i>*/}
                            <br/>
                            <br/>
                            Я стремлюсь к постоянному профессиональному и личностному росту, изучая новые подходы и
                            инструменты,
                            которые делают интернет удобнее и доступнее для всех.
                            <i> Моя цель — создавать продукты, которые не только решают задачи бизнеса, но и приносят
                                радость и
                                удобство пользователям. </i>
                            <i>
                                {/*  <b className="purple">*/}
                                {/*    {" "}*/}
                                {/*    Modern Javascript Library and Frameworks*/}
                                {/*  </b>*/}
                                {/*</i>*/}
                                {/*&nbsp; like*/}
                                {/*<i>*/}
                                {/*  <b className="purple"> React.js and Next.js</b>*/}
                            </i>
                        </p>
                    </Col>
                    <Col md={4} className="myAvtar">
                        <Tilt>
                            <img src={myImg} className="img-fluid" style={{'borderRadius': '55px'}} alt="avatar"/>
                        </Tilt>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="home-about-social">
                        <h1>Связаться с мной можно:</h1>
                        <p>
                            Не стесняйтесь <span className="purple">связаться </span>со мной
                        </p>

                        {/* Добавляем новый компонент формы */}
                        <ContactUs/>
                        {/*<ul className="home-about-social-links">*/}
                        {/*  <li className="social-icons">*/}
                        {/*    <a*/}
                        {/*        href="https://github.com/soumyajit4419"*/}
                        {/*        target="_blank"*/}
                        {/*        rel="noreferrer"*/}
                        {/*        className="icon-colour  home-social-icons"*/}
                        {/*    >*/}
                        {/*      <AiFillGithub />*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*  <li className="social-icons">*/}
                        {/*    <a*/}
                        {/*        href="https://twitter.com/Soumyajit4419"*/}
                        {/*        target="_blank"*/}
                        {/*        rel="noreferrer"*/}
                        {/*        className="icon-colour  home-social-icons"*/}
                        {/*    >*/}
                        {/*      <AiOutlineTwitter />*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*  <li className="social-icons">*/}
                        {/*    <a*/}
                        {/*        href="https://www.linkedin.com/in/soumyajit4419/"*/}
                        {/*        target="_blank"*/}
                        {/*        rel="noreferrer"*/}
                        {/*        className="icon-colour  home-social-icons"*/}
                        {/*    >*/}
                        {/*      <FaLinkedinIn />*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*  <li className="social-icons">*/}
                        {/*    <a*/}
                        {/*        href="https://www.instagram.com/soumyajit4419"*/}
                        {/*        target="_blank"*/}
                        {/*        rel="noreferrer"*/}
                        {/*        className="icon-colour home-social-icons"*/}
                        {/*    >*/}
                        {/*      <AiFillInstagram />*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*</ul>*/}
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Home2;
