import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/editor.png";
import evklid from "../../Assets/Projects/evklid.png";
import suicide from "../../Assets/Projects/suicide.png";
import lagoona from "../../Assets/Projects/lagoona.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Мои Недавние <strong className="purple">Работы </strong>
        </h1>
        <p style={{ color: "white" }}>
          Вот несколько проектов, над которыми я работал в последнее время.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={evklid}
              isBlog={false}
              title="Evklid--Landing"
              description="Создание макета Евклид с применением HTML и CSS, используя Flexbox для верстки. Проект адаптирован под мобильные устройства, следуя концепции Pixel Perfect, а именование классов выполнено по методологии БЭМ."
              // ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="https://ryzendeveloper.github.io/Evklid-landing/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={lagoona}
              isBlog={false}
              title="Lagoona--Landing"
              description="Создание макета Lagoona с применением HTML и CSS, с соблюдением концепции Pixel Perfect и нейминга по методологии БЭМ."
              // ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://ryzendeveloper.github.io/Lagoona-landing/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="3D Modeling Courses"
              description="Применена сетка библиотеки Bootstrap 4.0.0. Верстка адаптивная и кросс-браузерная, с соблюдением принципов Pixel Perfect, методологии БЭМ и стандартов доступности. Использованы технологии: HTML5, CSS3, а также библиотеки и плагины, включая Bootstrap 4.0.0."
              // ghLink="https://github.com/soumyajit4419/Editor.io"
              demoLink="https://ryzendeveloper.github.io/3D_modeling_courses/"
            />
          </Col>

          {/*<Col md={4} className="project-card">*/}
          {/*  <ProjectCard*/}
          {/*    imgPath={leaf}*/}
          {/*    isBlog={false}*/}
          {/*    title="Plant AI"*/}
          {/*    description="Used the plant disease dataset from Kaggle and trained a image classifer model using 'PyTorch' framework using CNN and Transfer Learning with 38 classes of various plant leaves. The model was successfully able to detect diseased and healthy leaves of 14 unique plants. I was able to achieve an accuracy of 98% by using Resnet34 pretrained model."*/}
          {/*    // ghLink="https://github.com/soumyajit4419/Plant_AI"*/}
          {/*    demoLink="https://plant49-ai.herokuapp.com/"*/}
          {/*  />*/}
          {/*</Col>*/}

          {/*<Col md={4} className="project-card">*/}
          {/*  <ProjectCard*/}
          {/*    imgPath={suicide}*/}
          {/*    isBlog={false}*/}
          {/*    title="Ai For Social Good"*/}
          {/*    description="Using 'Natural Launguage Processing' for the detection of suicide-related posts and user's suicide ideation in cyberspace  and thus helping in sucide prevention."*/}
          {/*    // ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"*/}
          {/*    demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley"*/}
          {/*  />*/}
          {/*</Col>*/}

          {/*<Col md={4} className="project-card">*/}
          {/*  <ProjectCard*/}
          {/*    imgPath={emotion}*/}
          {/*    isBlog={false}*/}
          {/*    title="Face Recognition and Emotion Detection"*/}
          {/*    description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.*/}
          {/*    Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."*/}
          {/*    // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"*/}
          {/*    demoLink="https://blogs.soumya-jit.tech/"*/}
          {/*  />*/}
          {/*</Col>*/}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
